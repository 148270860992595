import { Route, Routes, Link } from "react-router-dom";

const DaoHome = () => {
    return <h2>DaoHome</h2>
}

const DaoVote= () => {
    return <h2>DaoVote</h2>
}

export default function Dao() {
    return (
      <div>
        <h1>
          Dao
        </h1>
        <div>
          <Link to="./home">
            <button>nav-home</button>
          </Link>
          <Link to="./vote">
            <button>nav-vote</button>
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<DaoHome />} />
          <Route path="home" element={<DaoHome />} />
          <Route path="vote" element={<DaoVote />} />
        </Routes>
      </div>
    );
}