import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  return (
    <div
      style={{
        display: "flex",
        background: "#000",
        justifyContent: "space-around",
        height: "50px",
      }}
    >
      <Link to="/">
        <button>home</button>
      </Link>
      <div>v2-HEADER path-{location.pathname}</div>
      <Link to="/explore">
        <button>explore</button>
      </Link>
    </div>
  );
}
