import { Link } from "react-router-dom";

export default function Explore() {
  const go2V1 = () => {
    window.open(
      `${window.location.protocol}//${window.location.host}/v1/dao`,
      "_self"
    );
  }
  return (
    <div>
      <h1>Explore</h1>
      <div>
        <Link to="/">
          <button>go2home</button>
        </Link>
        <Link to="/dao">
          <button>v2dao</button>
        </Link>
        <button onClick={go2V1}>open v1 dao</button>
      </div>
    </div>
  );
}
