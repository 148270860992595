import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Explore from "./components/explore";
import Dao from "./components/dao";
import Home from "./components/home";
import Header from "./components/header";

export default function RouterLink () {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="explore" element={<Explore />} />
          <Route path="dao/*" element={<Dao />} />
        </Routes>
      </Router>
    );
}