import { Link } from "react-router-dom";
import { useState } from "react";

export default function Home() {
  const [id, setId] = useState();
  const setLocal = () => {
    sessionStorage.setItem("tokenid", 4);
  };

  const getLocal = () => {
    setId(sessionStorage.getItem("tokenid"));
  };

  const removeLocal = () => {
    sessionStorage.removeItem("tokenid");
    getLocal();
  };
  return (
    <div>
      <h1>Home</h1>
      <div>
        <Link to="explore">
          <button>go2explore</button>
        </Link>
        <Link to="dao">
          <button>go2dao</button>
        </Link>
      </div>
      <div>
        <button onClick={setLocal}>set session tokenid: 4</button>
      </div>
      <div>
        <button onClick={getLocal}>get session tokenid: {id}</button>
      </div>
      <div>
        <button onClick={removeLocal}>remove</button>
      </div>
    </div>
  );
}
